import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Modal, Icon, Button, Table, Message } from "semantic-ui-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { boolean, number, object, string } from "yup";
import { Checkbox, Dropdown, Input } from "../form";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../api";
import { isLoading } from "../../utils/isLoading";

const PRODUCT_MODELS = {
  Vehicle: {
    Tesla: ["Model 3", "Model Y"],
    Mercedes: ["EQC"],
  },
  Bike: {
    Lekker: ["Amsterdam Urban", "Jordaan Urban", "Amsterdam", "X"],
    Tern: ["HSD P9"],
  },
  Charger: {
    Teltonika: ["Teltonika"],
    Schneider: ["EV Link"],
    JetCharge: ["Chargemate"],
  },
};

const requiredString = string().required();
const optionalString = string().notRequired();
const positiveRequiredNumber = number().positive().required();

const productValidationSchema = {
  siteId: requiredString.label("Site"),
  name: requiredString.label("Name"),
  make: requiredString.label("Make"),
  model: requiredString.label("Model"),
  registrationPlate: requiredString.label("Registration plate"),
  hireable: boolean().label("Hireable"),
  hireRatePerHour: positiveRequiredNumber
    .label("Hire rate per hour")
    .typeError("Hire rate per hour must be a number"),
};

const vehicleValidationSchema = object({
  ...productValidationSchema,
  dailyCap: positiveRequiredNumber
    .label("Daily cap")
    .typeError("Daily cap must be a number"),
  weekendRate: positiveRequiredNumber
    .label("Weekend rate")
    .typeError("Weekend rate must be a number"),
  overnightRate: positiveRequiredNumber
    .label("Overnight rate")
    .typeError("Overnight rate must be a number"),
  etagNumber: optionalString.label("Etag number"),
  teslaApiId: requiredString.label("API ID"),
  accessTokenId: requiredString.label("Access token ID"),
});

const bikeValidationSchema = object({
  ...productValidationSchema,
  dailyCap: positiveRequiredNumber
    .label("Daily cap")
    .typeError("Daily cap must be a number"),
  weekendRate: positiveRequiredNumber
    .label("Weekend rate")
    .typeError("Weekend rate must be a number"),
  overnightRate: positiveRequiredNumber
    .label("Overnight rate")
    .typeError("Overnight rate must be a number"),
  deviceId: requiredString.label("Device ID"),
});

const chargerValidationSchema = object({
  ...productValidationSchema,
  deviceId: optionalString.label("Device ID"),
  identifier: optionalString
    .matches(/^OGCP[A-Za-z]\d{5}|SMP_\d{1,15}$/, {
      message: "Must be a valid identifier",
      excludeEmptyString: true,
    })
    .label("Identifier"),
  status: optionalString.label("Status"),
  guestModeEnabled: boolean().label("Guest mode enabled"),
});

export const EditProductModal = ({
  isOpen,
  onClose,
  product,
  sites,
  onSave,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [accessTokens, setAccessTokens] = useState({ state: "loading" });

  const defaultProductValues = {
    siteId: product.site.id,
    name: product.name,
    make: product.make,
    model: product.model,
    registrationPlate: product.registrationPlate,
    hireable: product.hireable,
    hireRatePerHour: product.hireRatePerHour,
  };

  let validationSchema = productValidationSchema;
  let defaultValues = defaultProductValues;

  switch (product.type) {
    case "Vehicle":
      validationSchema = vehicleValidationSchema;

      defaultValues = {
        ...defaultProductValues,
        dailyCap: product.dailyCap,
        weekendRate: product.weekendRate,
        overnightRate: product.overnightRate,
        etagNumber: product.etagNumber,
        teslaApiId: product.teslaApiId,
        accessTokenId: product.accessToken?.id,
      };
      break;
    case "Bike":
      validationSchema = bikeValidationSchema;

      defaultValues = {
        ...defaultProductValues,
        dailyCap: product.dailyCap,
        weekendRate: product.weekendRate,
        overnightRate: product.overnightRate,
        deviceId: product.dock?.deviceId,
      };
      break;
    case "Charger":
      validationSchema = chargerValidationSchema;

      defaultValues = {
        ...defaultProductValues,
        deviceId: product.deviceId,
        identifier: product.identifier,
        status: product.status,
        guestModeEnabled: product.guestModeEnabled,
      };
      break;
    default:
      break;
  }

  const formMethods = useForm({
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    setError,
    reset,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = formMethods;

  const handleSave = useCallback(
    async (data) => {
      const requestObject = {
        id: product.id,
        siteId: data.siteId,
        name: data.name,
        make: data.make,
        model: data.model,
        registrationPlate: data.registrationPlate,
        hireable: data.hireable,
        hireRatePerHour: data.hireRatePerHour,
        dailyCap: data.dailyCap ?? null,
        weekendRate: data.weekendRate ?? null,
        overnightRate: data.overnightRate ?? null,
        etagNumber: data.etagNumber ?? null,
        teslaApiId: data.teslaApiId ?? null,
        accessTokenId: data.accessTokenId ?? null,
        deviceId: data.deviceId ?? null,
        identifier: data.identifier ?? null,
        status: data.status ?? null,
        guestModeEnabled: data.guestModeEnabled ?? null,
      };

      try {
        await onSave(requestObject);
        onClose();
      } catch {
        setError("root.serverError", {
          type: "500",
          message: "API error. Contact Ike.",
        });
      }
    },
    [product.id, onSave, onClose, setError]
  );

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const fetchedAccessTokens = await api.getAccessTokens(accessToken);

    setAccessTokens({ state: "success", value: fetchedAccessTokens });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <FormProvider {...formMethods} handleSubmit={handleSubmit(handleSave)}>
      <Modal open={isOpen} onClose={onClose}>
        <Modal.Header>Override Product Details</Modal.Header>

        <Modal.Content>
          <Table definition columns={10}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2} />
                <Table.HeaderCell width={3}>Old details</Table.HeaderCell>
                <Table.HeaderCell width={5}>New details</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>Name</Table.Cell>
                <Table.Cell>{product.name}</Table.Cell>

                <Table.Cell>
                  <Input name="name" placeholder={product.name} fluid />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Make</Table.Cell>
                <Table.Cell>{product.make}</Table.Cell>

                <Table.Cell>
                  <Dropdown
                    name="make"
                    placeholder={product.make}
                    fluid
                    search={false}
                    options={Object.keys(PRODUCT_MODELS[product.type]).map(
                      (make) => ({
                        key: make,
                        text: make,
                        value: make,
                      })
                    )}
                    onChange={() => setValue("model", null)}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Model</Table.Cell>
                <Table.Cell>{product.model}</Table.Cell>

                <Table.Cell>
                  <Dropdown
                    name="model"
                    placeholder={product.model}
                    fluid
                    search={false}
                    options={
                      watch("make") &&
                      Object.values(
                        PRODUCT_MODELS[product.type][watch("make")]
                      ).map((model) => ({
                        key: model,
                        text: model,
                        value: model,
                      }))
                    }
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Registration plate</Table.Cell>
                <Table.Cell>{product.registrationPlate}</Table.Cell>

                <Table.Cell>
                  <Input
                    name="registrationPlate"
                    placeholder={product.registrationPlate}
                    fluid
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Site</Table.Cell>
                <Table.Cell>{product.site.name}</Table.Cell>

                <Table.Cell>
                  <Dropdown
                    name="siteId"
                    placeholder={product.site.name}
                    fluid
                    scrolling
                    options={sites.map((site) => ({
                      key: site.name,
                      text: site.name,
                      value: site.id,
                    }))}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Hireable</Table.Cell>
                <Table.Cell>
                  {product.hireable ? <CheckIcon /> : <CrossIcon />}
                </Table.Cell>

                <Table.Cell>
                  <Checkbox name="hireable" />
                </Table.Cell>
              </Table.Row>

              {product.type === "Vehicle" && (
                <>
                  <Table.Row>
                    <Table.Cell>Etag number</Table.Cell>
                    <Table.Cell>{product.etagNumber}</Table.Cell>

                    <Table.Cell>
                      <Input
                        name="etagNumber"
                        placeholder={product.etagNumber}
                        fluid
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>API ID</Table.Cell>
                    <Table.Cell>{product.teslaApiId}</Table.Cell>

                    <Table.Cell>
                      <Input name="teslaApiId" fluid />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Access token</Table.Cell>
                    <Table.Cell>{product.accessToken?.name}</Table.Cell>

                    <Table.Cell>
                      <Dropdown
                        name="accessTokenId"
                        label="Access token"
                        fluid
                        options={accessTokens.value?.map((accessToken) => ({
                          key: accessToken.id,
                          text: accessToken.name,
                          value: accessToken.id,
                          description: accessToken.provider,
                        }))}
                        loading={isLoading(accessTokens)}
                      />
                    </Table.Cell>
                  </Table.Row>
                </>
              )}

              <Table.Row>
                <Table.Cell>Hire rate per hour</Table.Cell>
                <Table.Cell>{product.hireRatePerHour}</Table.Cell>

                <Table.Cell>
                  <Input
                    name="hireRatePerHour"
                    placeholder={product.hireRatePerHour}
                    type="number"
                    icon="dollar"
                    iconPosition="left"
                    fluid
                  />
                </Table.Cell>
              </Table.Row>

              {product.type !== "Charger" && (
                <>
                  <Table.Row>
                    <Table.Cell>Weekend rate</Table.Cell>
                    <Table.Cell>{product.weekendRate}</Table.Cell>

                    <Table.Cell>
                      <Input
                        name="weekendRate"
                        placeholder={product.weekendRate}
                        type="number"
                        icon="dollar"
                        iconPosition="left"
                        fluid
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Overnight rate</Table.Cell>
                    <Table.Cell>{product.overnightRate}</Table.Cell>

                    <Table.Cell>
                      <Input
                        name="overnightRate"
                        placeholder={product.overnightRate}
                        type="number"
                        icon="dollar"
                        iconPosition="left"
                        fluid
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Daily cap</Table.Cell>
                    <Table.Cell>{product.dailyCap}</Table.Cell>

                    <Table.Cell>
                      <Input
                        name="dailyCap"
                        placeholder={product.dailyCap}
                        type="number"
                        icon="dollar"
                        iconPosition="left"
                        fluid
                      />
                    </Table.Cell>
                  </Table.Row>
                </>
              )}

              {product.type === "Charger" && (
                <>
                  <Table.Row>
                    <Table.Cell>Identifier</Table.Cell>
                    <Table.Cell>{product.identifier}</Table.Cell>

                    <Table.Cell>
                      <Input
                        name="identifier"
                        required={false}
                        placeholder={product.identifier}
                        fluid
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Status</Table.Cell>
                    <Table.Cell>{product.status}</Table.Cell>

                    <Table.Cell>
                      <Input
                        name="status"
                        required={false}
                        placeholder={product.status}
                        fluid
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Guest mode enabled</Table.Cell>
                    <Table.Cell>
                      {product.guestModeEnabled ? <CheckIcon /> : <CrossIcon />}
                    </Table.Cell>

                    <Table.Cell>
                      <Checkbox name="guestModeEnabled" />
                    </Table.Cell>
                  </Table.Row>
                </>
              )}

              {(product.type === "Bike" || product.type === "Charger") && (
                <Table.Row>
                  <Table.Cell>Device ID</Table.Cell>
                  <Table.Cell>
                    {product.deviceId ?? product.dock?.deviceId}
                  </Table.Cell>

                  <Table.Cell>
                    <Input
                      name="deviceId"
                      required={false}
                      placeholder={product.deviceId ?? product.dock?.deviceId}
                      fluid
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          {errors.root && (
            <Message error icon>
              <Icon name="times circle" />

              <Message.Content>
                <Message.Header>Something went wrong</Message.Header>
                {errors.root.serverError.message}
              </Message.Content>
            </Message>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={onClose}>
            <Icon name="close" /> Cancel
          </Button>

          <Button basic onClick={reset}>
            <Icon name="repeat" /> Reset
          </Button>

          <Button
            color="green"
            onClick={handleSubmit(handleSave)}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            <Icon name="checkmark" /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    </FormProvider>
  );
};

const CheckIcon = () => <Icon name="check" color="green" />;
const CrossIcon = () => <Icon name="close" color="red" />;

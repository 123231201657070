import React from "react";
import { Label, TextArea as SUITextArea } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";

/**
 * Enables the Semantic UI input component to be used with React Hook Form
 * @param {{
 *  name: string
 *  required: boolean
 * }} props
 */
export function TextArea({ name, required, label, placeholder, ...props }) {
  const { control } = useFormContext();
  const isRequired = required ?? true;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <>
          {label && (
            <label
              basic
              // Semantic UI label styles
              style={{
                display: "block",
                margin: "0 0 .28571429rem 0",
                color: "rgba(0, 0, 0, .87)",
                fontSize: ".92857143em",
                fontWeight: "700",
              }}
            >
              {label}
              <span style={{ color: "#db2828" }}>{isRequired && " *"}</span>
            </label>
          )}

          <div className="ui form">
            <SUITextArea
              {...props}
              {...field}
              placeholder={placeholder ?? label}
              // NOTE (Matt): Stop propagation to prevent keys typed in the input from
              // triggering events in any parent components
              onKeyDown={(e) => e.stopPropagation()}
              data-1p-ignore
              style={
                error && {
                  backgroundColor: "#fff6f6",
                  borderColor: "#e0b4b4",
                  color: "#9f3a38",
                  boxShadow: "none",
                }
              }
            />
          </div>

          {error && (
            <Label
              basic
              color="red"
              pointing="above"
              style={{ display: "block", width: "fit-content" }}
            >
              {error.message}
            </Label>
          )}
        </>
      )}
    />
  );
}

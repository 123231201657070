import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Button, Label, Transition } from "semantic-ui-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDamageReports } from "./DamageReportsContext";

const Navbar = () => {
  const [activeItem, setActiveItem] = useState("home");

  const { logout, isAuthenticated } = useAuth0();
  const { isLoading, badgeCount } = useDamageReports();

  const handleClick = (_, { name }) => {
    setActiveItem(name);
  };

  const LogoutButton = () => {
    return (
      <Button
        color="google plus"
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        Log out
      </Button>
    );
  };

  return (
    <>
      <Menu color="teal" inverted size="large">
        <Menu.Item
          name="home"
          active={activeItem === "home"}
          onClick={handleClick}
          as={Link}
          to="/"
        >
          <img src="/ohmie-logo.ico" alt="ohmie-logo" style={{ width: 30 }} />
        </Menu.Item>

        <Menu.Item
          name="bookings"
          active={activeItem === "bookings"}
          onClick={handleClick}
          as={Link}
          to="/bookings"
        >
          Bookings
        </Menu.Item>

        <Menu.Item
          name="users"
          active={activeItem === "users"}
          onClick={handleClick}
          as={Link}
          to="/users"
        >
          Users
        </Menu.Item>

        <Menu.Item
          name="sites"
          active={activeItem === "sites"}
          onClick={handleClick}
          as={Link}
          to="/sites"
        >
          Sites
        </Menu.Item>

        <Menu.Item
          name="vehicles"
          active={activeItem === "vehicles"}
          onClick={handleClick}
          as={Link}
          to="/vehicles"
        >
          Products
        </Menu.Item>

        <Menu.Item
          name="damages"
          active={activeItem === "damages"}
          onClick={handleClick}
          as={Link}
          to="/damages"
        >
          Damages
          <Transition animation="bounce" visible={isLoading}>
            {badgeCount === 0 ? (
              <></>
            ) : (
              <Label color="red" circular size="small">
                {badgeCount}
              </Label>
            )}
          </Transition>
        </Menu.Item>

        {isAuthenticated && (
          <Menu.Menu position="right">
            <Menu.Item position="right" as={LogoutButton} />
          </Menu.Menu>
        )}
      </Menu>
    </>
  );
};

export default Navbar;

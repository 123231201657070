import {
  Header,
  Label,
  Button,
  Grid,
  Table,
  TableHeader,
  Segment,
  Message,
} from "semantic-ui-react";
import { api } from "../api";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Loader } from "./Loader";

import EditOrderModal from "./modals/EditOrderModal";

const BookingOrders = ({ booking }) => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(Math.random());
  const [saveStatus, setSaveStatus] = useState({});
  const [isOrderEditorOpen, setIsOrderEditorOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    const accessToken = await getAccessTokenSilently();
    const fetchedOrders = await api.getOrdersForBooking(
      booking.id,
      accessToken
    );
    setOrders(fetchedOrders);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  const handleOverrideSaveClick = async (editedOrder) => {
    const accessToken = await getAccessTokenSilently();
    const requestObject = {
      total: editedOrder.total,
      couponsUsed: editedOrder.couponsUsed,
      preAuthorizedAmount: editedOrder.preAuthorizedAmount,
      status: editedOrder.status,
      transactionId: editedOrder.transactionId,
      paymentType: editedOrder.paymentType,
      paymentMethodId: editedOrder.paymentMethodId,
      paymentProcessor: editedOrder.paymentProcessor,
      stripePaymentMethodId: editedOrder.stripePaymentMethodId
    };
    await api
      .patchOrder(editedOrder.id, accessToken, requestObject)
      .then(() => {
        setSaveStatus({ status: "success", errorMessage: "" });
        setRender(Math.random());
      })
      .catch((_) => {
        setSaveStatus({
          status: "error",
          errorMessage: "API Error. Contact Ike",
        });
      });
  };

  const handleOpenOrderEditor = (order) => {
    setSelectedOrder(order);
    setIsOrderEditorOpen(true);
  };

  const handleCloseOrderEditor = () => {
    setSelectedOrder(null);
    setIsOrderEditorOpen(false);
  };

  return (
    <Loader isLoading={isLoading}>
      <Header>Booking Orders</Header>

      <Grid textAlign="left" stackable>
        <Grid.Column width={13}>
          <Table textAlign="center">
            <TableHeader>
              <Table.Row>
                <Table.HeaderCell>Time created</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Transaction id</Table.HeaderCell>
                <Table.HeaderCell>Processor</Table.HeaderCell>
                <Table.HeaderCell>Pre-authorised amount</Table.HeaderCell>
                <Table.HeaderCell>Coupons used</Table.HeaderCell>
                <Table.HeaderCell>Total settled</Table.HeaderCell>
                <Table.HeaderCell>Edit</Table.HeaderCell>
              </Table.Row>
            </TableHeader>

            <Table.Body>
              {orders.map((order) => (
                <Table.Row key={order.id}>
                  <Table.Cell>
                    <Moment format="llll Z" local>
                      {order.createdAt}
                    </Moment>
                  </Table.Cell>

                  <Table.Cell>
                    <Label circular className={order.status}>
                      {order.status}
                    </Label>
                  </Table.Cell>

                  <Table.Cell>{order.transactionId}</Table.Cell>
                  <Table.Cell>{order.paymentProcessor}</Table.Cell>

                  <Table.Cell>
                    ${order.preAuthorizedAmount.toFixed(2)}
                  </Table.Cell>

                  <Table.Cell>-${order.couponsUsed.toFixed(2)}</Table.Cell>
                  <Table.Cell>${order.total.toFixed(2)}</Table.Cell>

                  <Table.Cell>
                    <Button
                      basic
                      floated="right"
                      icon="edit"
                      onClick={() => handleOpenOrderEditor(order)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <Grid.Row textAlign="center">
            {saveStatus.status === "success" && (
              <Message positive>
                <Message.Header>Save was Successful!</Message.Header>
              </Message>
            )}
            {saveStatus.status === "error" && (
              <Message negative>
                <Message.Header>
                  Something went wrong - {saveStatus.errorMessage}
                </Message.Header>
              </Message>
            )}
          </Grid.Row>
        </Grid.Column>

        <Grid.Column width={3}>
          <Segment>
            <Header as="h3">
              <Header.Subheader>Total booking revenue</Header.Subheader>$
              {orders
                .reduce(
                  (total, order) => total + order.total - order.couponsUsed,
                  0
                )
                .toFixed(2)}
            </Header>
          </Segment>

          <Button
            icon="plus"
            color="blue"
            content="New transaction"
            floated="right"
            fluid
            disabled
          />
        </Grid.Column>
      </Grid>

      {selectedOrder !== null && (
        <EditOrderModal
          isOpen={isOrderEditorOpen}
          onClose={handleCloseOrderEditor}
          order={selectedOrder}
          onSave={(editedOrder) => handleOverrideSaveClick(editedOrder)}
        />
      )}
    </Loader>
  );
};

export default BookingOrders;

import React, { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Modal, Icon, Button, Table, Message, Label } from "semantic-ui-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { array, boolean, number, object, string } from "yup";
import { Dropdown, Input, Checkbox, TextArea } from "../form";

const SITE_TYPES = ["Residential", "Corporate", "Unallocated"];
const SITE_TYPE_OPTIONS = SITE_TYPES.map((type) => ({
  key: type,
  text: type,
  value: type.toLowerCase(),
}));

const SERVICES = ["Bike", "Charger", "Car"];
const SERVICE_OPTIONS = SERVICES.map((service) => ({
  key: service,
  text: service,
  value: service.toLowerCase(),
}));

const TIME_ZONES = ["Australia/Sydney", "Australia/Brisbane"];
const TIME_ZONE_OPTIONS = TIME_ZONES.map((timeZone) => ({
  key: timeZone,
  text: timeZone,
  value: timeZone,
}));

const requiredString = string()
  .required()
  .transform((value) => {
    return value === "" ? null : value;
  });
const requiredNumber = number()
  .required()
  .transform((value) => {
    return value === "" ? null : value;
  });
const requiredTimestamp = requiredString
  .matches(
    /\d{1,2}:\d{1,2}/,
    (field) => `${field.label} must be in the format of HH:MM`
  )
  .min(5)
  .max(8);

const validationSchema = object({
  imagePath: string().url().notRequired().label("Image path"),
  type: requiredString
    .oneOf(SITE_TYPES.map((type) => type.toLowerCase()))
    .label("Type"),
  servicesAvailable: array()
    .of(string().oneOf(SERVICES.map((service) => service.toLowerCase())))
    .ensure()
    .label("Services available"),
  maxChargerBookingDuration: requiredNumber
    .label("Maximum charger booking duration")
    .typeError("Maximum charger booking duration must be a number"),
  name: requiredString.label("Name"),
  displayName: requiredString.label("Display name"),
  address1: requiredString.label("Address 1"),
  address2: string().notRequired().label("Address 2"),
  acceptedAddresses: string().notRequired().label("Accepted addresses"),
  zip: requiredString
    .length(4, "Zip code must be exactly 4 digits")
    .matches(/\d{4}/, "Zip code must be a number")
    .label("Zip code"),
  timeZoneId: requiredString.oneOf(TIME_ZONES).label("Time zone"),
  latitude: requiredNumber
    .label("Latitude")
    .typeError("Latitude must be a number"),
  longitude: requiredNumber
    .label("Longitude")
    .typeError("Longitude must be a number"),
  businessHoursStart: requiredTimestamp.label("Business hours start"),
  businessHoursEnd: requiredTimestamp.label("Business hours end"),
  freeDuringBusinessHours: boolean().label("Free during business hours"),
  weekendStart: requiredTimestamp.label("Weekend start"),
  weekendEnd: requiredTimestamp.label("Weekend end"),
});

export const EditSiteModal = ({ site, isOpen, onClose, onSave }) => {
  const [chargerSelected, setChargerSelected] = useState(
    site.servicesAvailable.includes("charger")
  );

  const formMethods = useForm({
    defaultValues: {
      imagePath: site.imagePath,
      type: site.type,
      servicesAvailable: site.servicesAvailable,
      maxChargerBookingDuration: site.maxChargerBookingDuration,
      name: site.name,
      displayName: site.displayName,
      address1: site.address1,
      address2: site.address2,
      acceptedAddresses: site.acceptedAddresses,
      zip: site.zip,
      timeZoneId: site.timeZoneId,
      latitude: site.latitude,
      longitude: site.longitude,
      businessHoursStart: site.businessHoursStart,
      businessHoursEnd: site.businessHoursEnd,
      freeDuringBusinessHours: site.freeDuringBusinessHours,
      weekendStart: site.weekendStart,
      weekendEnd: site.weekendEnd,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting, errors },
  } = formMethods;

  const handleSave = useCallback(
    async (data) => {
      const requestObject = {
        id: site.id,
        imagePath: data.imagePath,
        type: data.type,
        servicesAvailable: data.servicesAvailable,
        maxChargerBookingDuration: data.maxChargerBookingDuration,
        name: data.name,
        displayName: data.displayName,
        address1: data.address1,
        address2: data.address2,
        acceptedAddresses: data.acceptedAddresses,
        zip: data.zip,
        timeZoneId: data.timeZoneId,
        latitude: data.latitude,
        longitude: data.longitude,
        businessHoursStart: data.businessHoursStart,
        businessHoursEnd: data.businessHoursEnd,
        freeDuringBusinessHours: data.freeDuringBusinessHours,
        weekendStart: data.weekendStart,
        weekendEnd: data.weekendEnd,
      };

      try {
        await onSave(requestObject);
        onClose();
      } catch {
        setError("root.serverError", {
          type: "500",
          message: "API error. Contact Ike.",
        });
      }
    },
    [onClose, onSave, setError, site.id]
  );

  const handleCancel = useCallback(
    (e) => {
      onClose(e);
    },
    [onClose]
  );

  const handleChargerSelect = useCallback((value) => {
    if (value.includes("charger")) {
      setChargerSelected(true);
    } else {
      setChargerSelected(false);
    }
  }, []);

  return (
    <FormProvider {...formMethods} handleSubmit={handleSubmit(handleSave)}>
      <Modal
        open={isOpen}
        onClick={(e) => e.stopPropagation()}
        onClose={handleCancel}
      >
        <Modal.Header>Override Site Details</Modal.Header>

        <Modal.Content>
          <Table definition columns={10}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2} />
                <Table.HeaderCell width={3}>Old details</Table.HeaderCell>
                <Table.HeaderCell width={5}>New details</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>Image path</Table.Cell>
                <Table.Cell>{site.imagePath}</Table.Cell>

                <Table.Cell>
                  <Input
                    name="imagePath"
                    placeholder={site.imagePath}
                    fluid
                    required={false}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Type</Table.Cell>
                <Table.Cell>{site.type}</Table.Cell>

                <Table.Cell>
                  <Dropdown
                    name="type"
                    placeholder={site.type}
                    fluid
                    options={SITE_TYPE_OPTIONS}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Services available</Table.Cell>

                <Table.Cell>
                  {site.servicesAvailable.map((service) => (
                    <Label key={service} className={service}>
                      {service}
                    </Label>
                  ))}
                </Table.Cell>

                <Table.Cell>
                  <Dropdown
                    name="servicesAvailable"
                    placeholder={site.servicesAvailable.toString()}
                    fluid
                    options={SERVICE_OPTIONS}
                    multiple
                    onChange={(_, { value }) => handleChargerSelect(value)}
                  />
                </Table.Cell>
              </Table.Row>

              {chargerSelected && (
                <Table.Row>
                  <Table.Cell>Max charger booking duration</Table.Cell>
                  <Table.Cell>{site.maxChargerBookingDuration}</Table.Cell>

                  <Table.Cell>
                    <Input
                      name="maxChargerBookingDuration"
                      placeholder={site.maxChargerBookingDuration}
                      type="number"
                      fluid
                    />
                  </Table.Cell>
                </Table.Row>
              )}

              <Table.Row>
                <Table.Cell>Name</Table.Cell>
                <Table.Cell>{site.name}</Table.Cell>

                <Table.Cell>
                  <Input name="name" placeholder={site.name} fluid />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Display name</Table.Cell>
                <Table.Cell>{site.displayName}</Table.Cell>

                <Table.Cell>
                  <Input
                    name="displayName"
                    placeholder={site.displayName}
                    fluid
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Address 1</Table.Cell>
                <Table.Cell>{site.address1}</Table.Cell>

                <Table.Cell>
                  <Input name="address1" placeholder={site.address1} fluid />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Address 2</Table.Cell>
                <Table.Cell>{site.address2}</Table.Cell>

                <Table.Cell>
                  <Input
                    name="address2"
                    placeholder={site.address2}
                    required={false}
                    fluid
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Accepted Addresses</Table.Cell>
                <Table.Cell>{site.acceptedAddresses}</Table.Cell>

                <Table.Cell>
                  <TextArea
                    name="acceptedAddresses"
                    rows={3}
                    placeholder={site.acceptedAddresses}
                    required={false}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>ZIP code</Table.Cell>
                <Table.Cell>{site.zip}</Table.Cell>

                <Table.Cell>
                  <Input
                    name="zip"
                    placeholder={site.zip}
                    type="number"
                    width={6}
                    fluid
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Time zone</Table.Cell>
                <Table.Cell>{site.timeZoneId}</Table.Cell>

                <Table.Cell>
                  <Dropdown
                    name="timeZoneId"
                    placeholder={site.timeZoneId}
                    fluid
                    options={TIME_ZONE_OPTIONS}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Latitude</Table.Cell>
                <Table.Cell>{site.latitude}</Table.Cell>

                <Table.Cell>
                  <Input
                    name="latitude"
                    placeholder={site.latitude}
                    type="number"
                    fluid
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Longitude</Table.Cell>
                <Table.Cell>{site.longitude}</Table.Cell>

                <Table.Cell>
                  <Input
                    name="longitude"
                    placeholder={site.longitude}
                    type="number"
                    fluid
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Business hours start</Table.Cell>
                <Table.Cell>{site.businessHoursStart}</Table.Cell>

                <Table.Cell>
                  <Input name="businessHoursStart" type="time" fluid />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Business hours end</Table.Cell>
                <Table.Cell>{site.businessHoursEnd}</Table.Cell>

                <Table.Cell>
                  <Input name="businessHoursEnd" type="time" fluid />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Free during business hours</Table.Cell>
                <Table.Cell>
                  {site.freeDuringBusinessHours ? <CheckIcon /> : <CrossIcon />}
                </Table.Cell>

                <Table.Cell>
                  <Checkbox name="freeDuringBusinessHours" fluid />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Weekend start</Table.Cell>
                <Table.Cell>{site.weekendStart}</Table.Cell>

                <Table.Cell>
                  <Input name="weekendStart" type="time" fluid />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Weekend end</Table.Cell>
                <Table.Cell>{site.weekendEnd}</Table.Cell>

                <Table.Cell>
                  <Input name="weekendEnd" type="time" fluid />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          {errors.root && (
            <Message error icon>
              <Icon name="times circle" />

              <Message.Content>
                <Message.Header>Something went wrong</Message.Header>
                {errors.root.serverError.message}
              </Message.Content>
            </Message>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={onClose}>
            <Icon name="close" /> Cancel
          </Button>

          <Button basic onClick={() => reset()}>
            <Icon name="repeat" /> Reset
          </Button>

          <Button
            color="green"
            onClick={handleSubmit(handleSave)}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            <Icon name="checkmark" /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    </FormProvider>
  );
};

const CheckIcon = () => <Icon name="check" color="green" />;
const CrossIcon = () => <Icon name="close" color="red" />;

import React from "react";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import { CreateVehicleModal } from "./CreateVehicleModal";
import CreateChargerModal from "./CreateChargerModal";
import CreateBikeModal from "./CreateBikeModal";

const trigger = (
  <span>
    <Icon name="plus" /> New product
  </span>
);

export default function CreateProductDropdown({ onCreate }) {
  return (
    <Dropdown as={Button} button color="blue" floated="right" trigger={trigger}>
      <Dropdown.Menu>
        <CreateVehicleModal
          trigger={<Dropdown.Item text="Vehicle" />}
          onCreate={onCreate}
        />

        <CreateChargerModal
          trigger={<Dropdown.Item text="Charger" />}
          onCreate={onCreate}
        />

        <CreateBikeModal
          trigger={<Dropdown.Item text="Bike" />}
          onCreate={onCreate}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

import React from "react";
import { Label, Checkbox as SUICheckbox } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";

/**
 * Enables the Semantic UI select component to be used with React Hook Form
 * @param {{
 *  name: string
 *  required: boolean
 *  selection: boolean
 *  search: boolean
 *  options: {
 *    key: *,
 *    text: string,
 *    value: string,
 *    description?: string
 *  }
 * }} props
 */
export function Checkbox({ name, required, label, formLabel, ...props }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ?? true,
      }}
      render={({
        field: { value, onChange, ref, ...field },
        fieldState: { error },
        formState: { defaultValues },
      }) => (
        <>
          {formLabel && (
            <label basic className="label">
              {formLabel}
              <span style={{ color: "#db2828" }}>{required && " *"}</span>
            </label>
          )}

          <SUICheckbox
            {...props}
            {...field}
            label={label}
            defaultChecked={defaultValues[name]}
            // NOTE (Matt): Semantic UI/HTML checkbox expects an empty string for true and undefined for false
            value={value ? "" : undefined}
            onChange={(e, data) => {
              onChange(data.checked);

              if (props.onChange) {
                props.onChange(e, data);
              }
            }}
            error={
              error && {
                content: error.message,
                pointing: "left",
              }
            }
          />

          {error && (
            <Label basic color="red" pointing="left">
              {error.message}
            </Label>
          )}
        </>
      )}
    />
  );
}

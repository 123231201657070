import React from "react";
import { Button, Message, Modal } from "semantic-ui-react";

/**
 * A delete confirmation modal
 * @param {{
 *  open: boolean
 *  confirmationText: string
 *  warningText: string
 *  confirmButtonText: string
 *  onCancel: () => {}
 *  onDelete: () => {}
 * }} props
 */
export function DeleteConfirmationModal({
  open,
  confirmationText,
  warningText,
  confirmButtonText,
  onCancel,
  onDelete,
}) {
  return (
    <>
      <Modal
        open={open}
        onClose={onCancel}
        onClick={(e) => e.stopPropagation()}
        size="tiny"
      >
        <Modal.Content>
          <Modal.Description>
            <p>{confirmationText}</p>

            {warningText && (
              <Message warning>
                <Message.Header>Warning</Message.Header>
                {warningText}
              </Message>
            )}
          </Modal.Description>
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={onCancel}>
            Cancel
          </Button>

          <Button
            content={confirmButtonText}
            labelPosition="left"
            icon="trash"
            onClick={onDelete}
            negative
          />
        </Modal.Actions>
      </Modal>
    </>
  );
}

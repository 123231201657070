import axios from 'axios';
import { API_URL, getHeaderObject } from './apiUtils'

const getSites = async (accessToken) => {
  let data = []
  const url = `${API_URL}/sites`;

  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const getSiteById = async (id, accessToken) => {
  let data = {}
  const url = `${API_URL}/sites/${id}`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const patchSite = async (id, siteObject, accessToken) => {
  let success = false;
  const url = `${API_URL}/sites/${id}`;

  await axios.patch(url, siteObject, getHeaderObject(accessToken))
    .then(_ => { success = true })
    .catch(err => console.log("Error occurred while updating site", err))

  return success;
}

const patchDriversLicense = async (id, driversLicenseObject, accessToken) => {
  let success = false;
  const url = `${API_URL}/drivers_licenses/${id}`;

  await axios.patch(url, driversLicenseObject, getHeaderObject(accessToken))
    .then(_ => { success = true })
    .catch(err => console.log("Error occurred while updating driver's license", err))

  return success;
}

const getUsers = async (accessToken) => {
  let data = []
  const url = `${API_URL}/users`;

  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const getUserById = async (id, accessToken) => {
  let data = {}
  const url = `${API_URL}/users/${id}`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const getUsersAtSite = async (siteId, accessToken) => {
  let data = []
  const url = `${API_URL}/sites/${siteId}/users`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const getUsersSiteAssignmentRequests = async (userId, accessToken) => {
  let data = []
  const url = `${API_URL}/users/${userId}/site_assignment_requests`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const getUsersCoupons = async (userId, accessToken) => {
  let data = [];
  const url = `${API_URL}/users/${userId}/coupons`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
}

const generateCouponForUser = async (user, amount, accessToken) => {
  let data = {};
  const url = `${API_URL}/users/${user.id}/coupons`;
  const letterLimit = 3; //take first 3 letters of first name for code or pad with 0's/

  var firstName = user.firstName.slice(0, letterLimit).toUpperCase();
  if (firstName.length < 3) firstName = firstName.padEnd(3, '0');

  const codeSuffix = Math.floor(Math.random() * 1000).toString().padStart(3, '0');

  const code = firstName + codeSuffix;

  const requestParams = {
    'value': amount,
    'code': code
  };
  await axios.post(url, requestParams, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
  return data;
}

const assignUserToSite = async (siteId, userId, accessToken) => {
  let success = false;
  const url = `${API_URL}/user_sites`;
  const requestParams = {
    userId,
    siteId
  };

  await axios.post(url, requestParams, getHeaderObject(accessToken))
    .then(_ => {
      success = true;
    })
    .catch(err => console.log(err));

  return success;
}

const removeUserFromSite = async (siteId, userId, accessToken) => {
  let success = false;
  const url = `${API_URL}/user_sites`;
  const requestParams = {
    userId,
    siteId
  };

  await axios.delete(url, {
    data: requestParams,
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
    .then(_ => {
      success = true;
    })
    .catch(err => console.log(err));

  return success;
}

const getLicences = async (accessToken) => {
  let data = []
  const url = `${API_URL}/drivers_licenses`;

  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const getBookingById = async (id, accessToken) => {
  let data = {};
  const url = `${API_URL}/bookings/${id}`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const patchBooking = async (id, accessToken, requestObject) => {
  let data = {}
  const url = `${API_URL}/bookings/${id}`;
  await axios.patch(url, requestObject, getHeaderObject(accessToken))
    .then(res => data = res.data)
  return data;
}

const getVehicleBookings = async (siteId, accessToken) => {
  let data = []
  const url = `${API_URL}/sites/${siteId}/bookings`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const getProductsAtSite = async (siteId, accessToken) => {
  let data = []
  const url = `${API_URL}/sites/${siteId}/products`;

  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
    
  return data;
}

const getAllBookings = async (status, accessToken) => {
  let data = []

  const url = new URL("bookings", `${API_URL}/`);
  url.searchParams.append("status", status);

  await axios.get(url, getHeaderObject(accessToken))
    .then(res => data = res.data)
    .catch(err => console.log(err));
  return data;
}

const getVehicles = async (accessToken) => {
  let data = []
  const url = `${API_URL}/vehicles`;

  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const getVehicleById = async (id, accessToken) => {
  let data = {}
  const url = `${API_URL}/vehicles/${id}`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const startCharging = async (id, accessToken) => {
  let success = false;
  const url = `${API_URL}/chargers/${id}/remote_start_transaction`;
  const requestParams = {
  };

  await axios.post(url, requestParams, getHeaderObject(accessToken))
    .then(_ => {
      success = true;
    })
    .catch(err => console.log(err));

  return success;
}


const stopCharging = async (id, accessToken) => {
  let success = false;
  const url = `${API_URL}/chargers/${id}/remote_stop_transaction`;
  const requestParams = {
  };
  
  await axios.post(url, requestParams, getHeaderObject(accessToken))
    .then(_ => {
      success = true;
    })
    .catch(err => console.log(err));

  return success;
}

const getOrdersForBooking = async (id, accessToken) => {
  let data = {};
  const url = `${API_URL}/bookings/${id}/orders`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const patchOrder = async (id, accessToken, requestObject) => {
  let data = {};
  const url = `${API_URL}/orders/${id}`;
  await axios.patch(url, requestObject, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const patchVehicle = async (id, accessToken, requestObject) => {
  let data = {};
  const url = `${API_URL}/vehicles/${id}`;
  await axios.patch(url, requestObject, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const getAllDamageReports = async (accessToken) => {
  let data = []
  const url = `${API_URL}/damage_reports`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => data = res.data)
    .catch(err => console.log(err));
  return data;
}

const getNewDamageReports = async (accessToken) => {
  let data = []
  const url = `${API_URL}/damage_reports/count`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => data = res.data)
    .catch(err => console.log(err));
  return data;
}

const getDamageReportById = async (id, accessToken) => {
  let data = []
  const url = `${API_URL}/damage_reports/${id}`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => data = res.data)
    .catch(err => console.log(err));
  return data;
}

const getDamageReportsForVehicle = async (id, accessToken) => {
  let data = []
  const url = `${API_URL}/vehicles/${id}/damage_reports`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => data = res.data)
    .catch(err => console.log(err));
  return data;
}

const patchDamageReport = async (id, accessToken, requestObject) => {
  let data = {};
  const url = `${API_URL}/damage_reports/${id}`;
  await axios.patch(url, requestObject, getHeaderObject(accessToken))
  .then(res => {
    data = res.data;
  })
  .catch(err => console.log(err));
return data;
}

const patchDamageImage = async (id, accessToken, requestObject) => {
  let data = {};
  const url = `${API_URL}/damage_images/${id}`;
  await axios.patch(url, requestObject, getHeaderObject(accessToken))
  .then(res => {
    data = res.data;
  })
  .catch(err => console.log(err));
return data;
}

const getUserAdminIssues = async (id, accessToken) => {
  let data = {};
  const url = `${API_URL}/users/${id}/issues`;
  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const createNewAdminIssue = async (id, accessToken, requestObject) => {
  let data = {};
  const url = `${API_URL}/issues/${id}`;
  await axios.post(url, requestObject, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const patchIssue = async (id, accessToken, requestObject) => {
  let data = {};
  const url = `${API_URL}/issues/${id}`;
  await axios.patch(url, requestObject, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const sendSms = async (accessToken, requestObject) => {
  const url = `${API_URL}/sms/send`;

  return (await axios.post(url, requestObject, getHeaderObject(accessToken))).data;
}

const getMessagesForConversation = async (userId, accessToken) => {
  let data = [];
  const url = `${API_URL}/sms/messages/${userId}`;

  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const getAccessTokens = async (accessToken) => {
  let data = [];
  const url = `${API_URL}/access_tokens`;

  await axios.get(url, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const createSite = async (accessToken, requestObject) => {
  const url = `${API_URL}/sites`;
  
  return (await axios.post(url, requestObject, getHeaderObject(accessToken))).data;
}

const createBike = async (accessToken, requestObject) => {
  const url = `${API_URL}/bikes`;

  return (await axios.post(url, requestObject, getHeaderObject(accessToken))).data;
}

const createCharger = async (accessToken, requestObject) => {
  const url = `${API_URL}/chargers`;

  return (await axios.post(url, requestObject, getHeaderObject(accessToken))).data;
}

const createVehicle = async (accessToken, requestObject) => {
  const url = `${API_URL}/vehicles`;

  return (await axios.post(url, requestObject, getHeaderObject(accessToken))).data;
}

const getBookingPrice = async (bookingId, requestObject, accessToken) => {
  let data = [];
  const url = `${API_URL}/bookings/${bookingId}/price`;

  await axios.post(url, requestObject, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const endBooking = async (bookingId, requestObject, accessToken) => {
  let data = [];
  const url = `${API_URL}/bookings/${bookingId}/end_booking`;

  await axios.post(url, requestObject, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })
    .catch(err => console.log(err));
  return data;
}

const getMileageBefore = async (bookingId, accessToken) => {
  const url = `${API_URL}/vehicles/mileage_before/${bookingId}`;

  return (await axios.get(url, getHeaderObject(accessToken))).data;
}

const getMileageAfter = async (bookingId, accessToken) => {
  const url = `${API_URL}/vehicles/mileage_after/${bookingId}`;

  return (await axios.get(url, getHeaderObject(accessToken))).data;
}

const getMatchingBookings = async (accessToken, requestObject) => {
  let data = [];
  const url = `${API_URL}/bookings/get_matching_bookings`;

  await axios.post(url, requestObject, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })

  return data;
}

const createBooking = async (accessToken, requestObject) => {
  let data = [];
  const url = `${API_URL}/bookings`;

  await axios.post(url, requestObject, getHeaderObject(accessToken))
    .then(res => {
      data = res.data;
    })

  return data;
}

const deleteProduct = async (vehicleId, accessToken) => {
  const url = `${API_URL}/vehicles/${vehicleId}`;

  return (await axios.delete(url, getHeaderObject(accessToken)));
}

const deleteSite = async (siteId, accessToken) => {
  const url = `${API_URL}/sites/${siteId}`;

  return (await axios.delete(url, getHeaderObject(accessToken)));
}

export const api = {
  getSites,
  getUserById,
  getUsers,
  getUsersAtSite,
  getUsersSiteAssignmentRequests,
  getUsersCoupons,
  generateCouponForUser,
  assignUserToSite,
  getSiteById,
  patchSite,
  removeUserFromSite,
  patchDriversLicense,
  getLicences,
  getVehicleBookings,
  getProductsAtSite,
  getBookingById,
  getAllBookings,
  getVehicles,
  getVehicleById,
  startCharging,
  stopCharging,
  patchBooking,
  getOrdersForBooking,
  patchOrder,
  patchVehicle,
  getAllDamageReports,
  getNewDamageReports,
  getDamageReportById,
  getDamageReportsForVehicle,
  patchDamageReport,
  patchDamageImage,
  getUserAdminIssues,
  createNewAdminIssue,
  patchIssue,
  sendSms,
  getMessagesForConversation,
  getAccessTokens,
  createSite,
  createBike,
  createCharger,
  createVehicle,
  endBooking,
  getMileageBefore,
  getMileageAfter,
  getBookingPrice,
  getMatchingBookings,
  createBooking,
  deleteProduct,
  deleteSite
};

import { useState, useEffect, useCallback } from "react";
import { Table, Icon, Popup, Label, Grid, Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api/index";
import config from "../config";
import { Loader } from "../components/Loader";
import { Pagination, getPaginatedItems } from "../components/Pagination";
import { TextInput } from "../components/TextInput";
import { isLoading } from "../utils/isLoading";
import { ButtonGroup } from "../components/ButtonGroup";
import { openInNewTab } from "../utils/openInNewTab";
import CreateSiteModal from "../components/modals/CreateSiteModal";
import { useUserAccess } from "../components/UserAccessManager";
import { zipPrefixMap } from "../utils/location";
import { DeleteConfirmationModal } from "../components/DeleteConfirmationModal";
import { EditSiteModal } from "../components/modals/EditSiteModal";

const Sites = () => {
  const navigate = useNavigate();
  const [isSiteEditorOpen, setIsSiteEditorOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [sites, setSites] = useState({ state: "loading", value: [] });
  const [filteredSites, setFilteredSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState();
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({
    searchValue: "",
    state: "All",
  });

  const { getAccessTokenSilently } = useAuth0();
  const adminUser = useAuth0().user;
  const { isCommunityManager } = useUserAccess();

  const stateRoleFilter = useCallback(() => {
    if (adminUser[`${config.auth0.NAMESPACE}/roles`].includes("nsw")) {
      setFilters((prev) => ({ ...prev, state: "NSW" }));
    } else if (adminUser[`${config.auth0.NAMESPACE}/roles`].includes("vic")) {
      setFilters((prev) => ({ ...prev, state: "VIC" }));
    }
  }, [adminUser]);

  const applyFilters = useCallback((sites, filters) => {
    return sites.value.filter((site) => {
      // Search in site name
      const searchMatch =
        filters.searchValue === "" ||
        site.name.toLowerCase().includes(filters.searchValue.toLowerCase());

      // Show all sites, or only sites where the zip code is the selected zip code
      const stateMatch =
        filters.state === "All" ||
        site.zip.toString().startsWith(zipPrefixMap[filters.state]);

      return searchMatch && stateMatch;
    });
  }, []);

  const filterSites = useCallback(
    (sites, filters) => {
      const filteredSiteResults = applyFilters(sites, filters);
      setFilteredSites(filteredSiteResults);
      setActivePage(1);
    },
    [applyFilters]
  );

  const updateFilters = (updatedFilter) => {
    setFilters((prev) => ({ ...prev, ...updatedFilter }));
  };

  const fetchData = useCallback(async () => {
    setSites({ state: "loading", value: [] });

    const accessToken = await getAccessTokenSilently();
    const fetchedSites = await api.getSites(accessToken);

    setSites({ state: "success", value: fetchedSites });
    stateRoleFilter();

    setFilteredSites(fetchedSites);
  }, [getAccessTokenSilently, stateRoleFilter]);

  const handleDeleteSite = useCallback(
    async (e, siteId) => {
      e.stopPropagation();

      try {
        const accessToken = await getAccessTokenSilently();

        await api.deleteSite(siteId, accessToken);
        setIsDeleteConfirmationOpen(false);
        fetchData();
      } catch (e) {
        console.log(e);
      }
    },
    [fetchData, getAccessTokenSilently]
  );

  const handleOpenDeleteConfirmation = useCallback((e, site) => {
    e.stopPropagation();
    setSelectedSite(site);
    setIsDeleteConfirmationOpen(true);
  }, []);

  const handleCancelDeleteSite = useCallback((e) => {
    e.stopPropagation();
    setIsDeleteConfirmationOpen(false);
  }, []);

  const handleEditSite = useCallback(
    async (data) => {
      const accessToken = await getAccessTokenSilently();

      const requestObject = {
        imagePath: data.imagePath,
        type: data.type,
        servicesAvailable: data.servicesAvailable,
        maxChargerBookingDuration: data.maxChargerBookingDuration,
        name: data.name,
        displayName: data.displayName,
        address1: data.address1,
        address2: data.address2,
        acceptedAddresses: data.acceptedAddresses,
        zip: data.zip,
        timeZoneId: data.timeZoneId,
        latitude: data.latitude,
        longitude: data.longitude,
        businessHoursStart: data.businessHoursStart,
        businessHoursEnd: data.businessHoursEnd,
        freeDuringBusinessHours: data.freeDuringBusinessHours,
        weekendStart: data.weekendStart,
        weekendEnd: data.weekendEnd,
      };

      await api.patchSite(data.id, requestObject, accessToken);
      fetchData();
    },
    [fetchData, getAccessTokenSilently]
  );

  const handleOpenSiteEditor = useCallback((e, site) => {
    e.stopPropagation();

    setSelectedSite(site);
    setIsSiteEditorOpen(true);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    filterSites(sites, filters);
  }, [filterSites, filters, sites]);

  const handleGetVehicleBookingsClick = (site) => {
    navigate(`${site.id}/vehiclebookings?name=${site.name}`);
  };

  return (
    <>
      <Grid stackable columns={3}>
        <Grid.Column>
          <TextInput
            fluid
            placeholder="Site name"
            onSubmit={(updatedFilter) =>
              updateFilters({ searchValue: updatedFilter })
            }
          />
        </Grid.Column>

        <Grid.Column>
          <ButtonGroup
            content={["All", "NSW", "VIC", "QLD"]}
            onClick={(state) => updateFilters({ state })}
          />
        </Grid.Column>

        <Grid.Column>
          {!isCommunityManager && (
            <CreateSiteModal
              onCreate={fetchData}
              trigger={
                <Button color="blue" floated="right">
                  <Icon name="plus" />
                  New site
                </Button>
              }
            />
          )}
        </Grid.Column>
      </Grid>

      <Loader isLoading={isLoading(sites)}>
        <Table selectable textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Address 1</Table.HeaderCell>
              <Table.HeaderCell>Zip</Table.HeaderCell>
              <Table.HeaderCell>Working Hours</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Services</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {getPaginatedItems(filteredSites, activePage).map((site) => (
              <Table.Row
                key={site.id}
                onClick={() => handleGetVehicleBookingsClick(site)}
                onMouseUp={(e) =>
                  openInNewTab(
                    e,
                    `sites/${site.id}/vehiclebookings?name=${site.name}`
                  )
                }
              >
                <Table.Cell>{site.name}</Table.Cell>
                <Table.Cell
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "8px",
                  }}
                >
                  {site.address1}

                  {site.acceptedAddresses && (
                    <Popup
                      mouseEnterDelay={800}
                      content={site.acceptedAddresses}
                      offset={[-7, 0]} // NOTE (Matt): Without this, the pointer is not centred above the icon due to the icon's margin
                      trigger={
                        <Icon
                          name="ellipsis horizontal"
                          color="blue"
                          size="large"
                        />
                      }
                    />
                  )}
                </Table.Cell>
                <Table.Cell>{site.zip}</Table.Cell>

                <Table.Cell>
                  {site.businessHoursStart} - {site.businessHoursEnd}
                </Table.Cell>

                <Table.Cell>{site.type}</Table.Cell>

                <Table.Cell>
                  {site.servicesAvailable.map((service) => (
                    <Label key={service} className={service}>
                      {service}
                    </Label>
                  ))}
                </Table.Cell>

                <Table.Cell
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "8px",
                  }}
                >
                  <span>
                    <ActionButton
                      popupText="Show assigned users"
                      iconName="users"
                      destination={`${site.id}/users`}
                    />

                    <ActionButton
                      popupText="Show vehicle bookings"
                      iconName="calendar"
                      destination={`${site.id}/vehiclebookings?name=${site.name}`}
                    />
                  </span>

                  {!isCommunityManager && (
                    <>
                      <span
                        style={{
                          borderLeft: "1px solid rgba(34, 36, 38, .1)",
                          margin: "0 .25rem 0 0",
                        }}
                      />

                      <span>
                        <Popup
                          mouseEnterDelay={800}
                          content="Edit site"
                          offset={[-7, 0]} // NOTE (Matt): Without this, the pointer is not centred above the icon due to the icon's margin
                          trigger={
                            <Icon
                              link
                              name="edit"
                              color="blue"
                              size="large"
                              onClick={(e) => handleOpenSiteEditor(e, site)}
                            />
                          }
                        />
                      </span>

                      <Popup
                        mouseEnterDelay={800}
                        content="Delete site"
                        offset={[-7, 0]} // NOTE (Matt): Without this, the pointer is not centred above the icon due to the icon's margin
                        trigger={
                          <Icon
                            link
                            name="trash"
                            color="red"
                            size="large"
                            onClick={(e) =>
                              handleOpenDeleteConfirmation(e, site)
                            }
                          />
                        }
                      />
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {isSiteEditorOpen && (
          <EditSiteModal
            site={selectedSite}
            isOpen={isSiteEditorOpen}
            onClose={() => setIsSiteEditorOpen(false)}
            onSave={handleEditSite}
          />
        )}

        {isDeleteConfirmationOpen && (
          <DeleteConfirmationModal
            open={isDeleteConfirmationOpen}
            confirmationText={`Are you sure you want to delete ${selectedSite.name}?`}
            warningText="This will also delete all bookings and products (including their docks, if applicable) at the site."
            confirmButtonText="Yes, delete site"
            onCancel={handleCancelDeleteSite}
            onDelete={(e) => handleDeleteSite(e, selectedSite.id)}
          />
        )}

        <Grid stackable>
          <Grid.Column>
            <Pagination
              activePage={activePage}
              onPageChange={(page) => setActivePage(page)}
              itemQuantity={filteredSites.length}
            />
          </Grid.Column>
        </Grid>
      </Loader>
    </>
  );
};

const ActionButton = ({ popupText, iconName, destination }) => {
  const navigate = useNavigate();

  const handleButtonClick = useCallback(
    (e, destination) => {
      e.stopPropagation();
      navigate(destination);
    },
    [navigate]
  );

  return (
    <Popup
      mouseEnterDelay={800}
      content={popupText}
      offset={[-7, 0]} // NOTE (Matt): Without this, the pointer is not centred above the icon due to the icon's margin
      trigger={
        <Icon
          link
          name={iconName}
          color="blue"
          size="large"
          onClick={(e) => handleButtonClick(e, destination)}
          onMouseUp={(e) => openInNewTab(e, `sites/${destination}`)}
        />
      }
    />
  );
};

export default Sites;

import { Header, Grid, Label, Button, Tab, TabPane } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api";
import { Loader } from "../components/Loader";
import { useUserAccess } from "../components/UserAccessManager";

import VehicleDetails from "../components/VehicleDetails";
import ChargerControls from "../components/ChargerControls";
import { EditProductModal } from "../components/modals/EditProductModal";
import DamageReportsList from "../components/damageReportList";

const VehicleOverview = () => {
  const { isCommunityManager } = useUserAccess();
  const { getAccessTokenSilently } = useAuth0();

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(Math.random());
  const [vehicle, setVehicle] = useState({});
  const [saveStatus, setSaveStatus] = useState({});
  const [isVehicleEditorOpen, setIsVehicleEditorOpen] = useState(false);
  const [sites, setSites] = useState([]);
  const [vehicleDamages, setVehicleDamages] = useState([]);

  const filterDamages = useCallback(
    (type) => {
      return vehicleDamages.filter((damage) => damage.type === type);
    },
    [vehicleDamages]
  );

  const damagesPanes = [
    {
      menuItem: "Verified (known)",
      filter: "verified",
    },
    {
      menuItem: "New",
      filter: "found",
    },
    {
      menuItem: "Archived",
      filter: "archived",
    },
    {
      menuItem: "Discarded",
      filter: "discarded",
    },
  ].map((pane) => ({
    menuItem: pane.menuItem,
    render: () => (
      <TabPane className="damageTabs">
        <DamageReportsList
          damages={filterDamages(pane.filter)}
          labels={false}
          vehicleBadges={false}
        />
      </TabPane>
    ),
  }));

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();

      const fetchedVehicle = await api.getVehicleById(id, accessToken);
      const fetchedSites = await api.getSites(accessToken);
      const fetchedDamages = await api.getDamageReportsForVehicle(
        id,
        accessToken
      );

      setVehicle(fetchedVehicle);
      setSites(fetchedSites);
      setVehicleDamages(fetchedDamages);

      setIsLoading(false);
    };

    fetchData();
  }, [getAccessTokenSilently, id, render]);

  const handleOverrideSaveClick = useCallback(
    async (editedVehicleRequest) => {
      const accessToken = await getAccessTokenSilently();
      const requestObject = {
        siteId: editedVehicleRequest.siteId,
        etagNumber: editedVehicleRequest.etagNumber,
        name: editedVehicleRequest.name,
        make: editedVehicleRequest.make,
        model: editedVehicleRequest.model,
        registrationPlate: editedVehicleRequest.registrationPlate,
        hireable: editedVehicleRequest.hireable,
        hireRatePerHour: editedVehicleRequest.hireRatePerHour,
        dailyCap: editedVehicleRequest.dailyCap,
        teslaApiId: editedVehicleRequest.teslaApiId,
        weekendRate: editedVehicleRequest.weekendRate,
        overnightRate: editedVehicleRequest.overnightRate,
        deviceId: editedVehicleRequest.deviceId,
        identifier: editedVehicleRequest.identifier,
        status: editedVehicleRequest.status,
        guestModeEnabled: editedVehicleRequest.guestModeEnabled,
        accessTokenId: editedVehicleRequest.accessTokenId,
      };

      await api
        .patchVehicle(editedVehicleRequest.id, accessToken, requestObject)
        .then(() => {
          setSaveStatus({ status: "success", errorMessage: "" });
          setRender(Math.random());
        })
        .catch((_) => {
          setSaveStatus({
            status: "error",
            errorMessage: "API Error. Contact Ike",
          });
        });
    },
    [getAccessTokenSilently]
  );

  return (
    <Loader isLoading={isLoading}>
      <Grid centered stackable columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" textAlign="center">
              Vehicle details
            </Header>

            <VehicleDetails vehicle={vehicle} site={vehicle.site} />

            {!isCommunityManager && (
              <>
                <Button
                  color="blue"
                  floated="right"
                  icon="edit"
                  content="Edit vehicle"
                  onClick={() => setIsVehicleEditorOpen(true)}
                />

                <EditProductModal
                  isOpen={isVehicleEditorOpen}
                  onClose={() => setIsVehicleEditorOpen(false)}
                  product={vehicle}
                  sites={sites}
                  onSave={(editedVehicleRequest) =>
                    handleOverrideSaveClick(editedVehicleRequest)
                  }
                />
              </>
            )}
          </Grid.Column>

          <Grid.Column textAlign="center">
            <Header as="h3">Controls</Header>

            <Grid.Row textAlign="center" centered columns={1}>
              {vehicle.type === "Charger" ? (
                <ChargerControls
                  vehicle={vehicle}
                  site={vehicle.site}
                  setRender={setRender}
                />
              ) : (
                <Label size="large">No controls available.</Label>
              )}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Header as="h3">Damages</Header>
        </Grid.Row>

        <Grid.Column width={12}>
          <Tab
            menu={{ secondary: true, pointing: true, renderActiveOnly: false }}
            panes={damagesPanes}
          />
        </Grid.Column>
      </Grid>
    </Loader>
  );
};

export default VehicleOverview;
